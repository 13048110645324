<template>
  <el-row type="flex" justify="center" align="middle" :style="{ flex: 1 }">
    <el-col :xs="24" :sm="18" :md="12">
      <el-card class="box-card password-card" v-loading="loading">
        <el-row
          type="flex"
          justify="center"
          :style="{ 'margin-bottom': '22px' }"
        >
          <el-col :xl="24" style="text-align: center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logo"
              class="password-logo"
            ></svg>
          </el-col>
        </el-row>
        <transition name="fade" mode="out-in">
          <div v-if="pending">
            <el-row style="margin-bottom: 1rem">
              <p class="password-welcome">
                Please enter your email below to recover your account's
                password. If you do not have an account, please reach out to
                your representative.
              </p>
            </el-row>
            <el-form
              @submit.native.prevent="submit"
              :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
              label-width="100px"
            >
              <el-form-item
                :error="
                  form.errors.first('email') ? form.errors.first('email') : null
                "
                label="Email"
                required
              >
                <el-input
                  v-model="email"
                  type="email"
                  placeholder="Your Email"
                  @focus="form.errors.clear('email')"
                  v-input-focus
                  required
                ></el-input>
              </el-form-item>

              <el-button
                type="primary"
                plain
                medium
                native-type="submit"
                :style="{ width: '100%' }"
                :disabled="loading"
              >
                Submit
              </el-button>
            </el-form>
          </div>
          <el-row v-else style="text-align: center">
            Password email sent successfully. Please check your email to
            proceed.
          </el-row>
        </transition>
        <div class="return-to-login-button">
          <router-link
            class="el-button el-button--text"
            :to="{ name: 'login' }"
          >
            Return to Login
          </router-link>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.password-logo {
  max-height: 150px;
  fill: rgba(212, 161, 0, 0.4);
  transition: fill 0.5s ease;
  margin-bottom: 15px;
}

.password-card:hover .password-logo {
  fill: rgba(212, 161, 0, 0.9);
}

.password-welcome {
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

.return-to-login-button {
  a {
    padding: 0;
  }
  text-align: center;
  margin: 1rem auto 0;
}
</style>

<script>
import Form from "form-backend-validation"
import svg from "@/theme/svg"

export default {
  name: "ForgotPassword",

  mixins: [],

  data() {
    return {
      form: new Form(),
      logo: svg.comet,
      email: "",
      loading: false,
      pending: true,
    }
  },

  watch: {},

  computed: {
    action() {
      return "auth/forgot"
    },
  },

  methods: {
    submit() {
      this.loading = true
      let data = {
        email: this.email,
      }
      this.axios
        .post(this.action, data)
        .then(() => {
          this.pending = false
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (
            resp.response &&
            [422, 423].indexOf(resp.response.status) !== -1
          ) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {},
}
</script>
